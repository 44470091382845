<template>

    <vendor-flyout
        ref="vendorFlyoutRef"
        v-bind="vendorProps"
    />

    <contract-flyout
        ref="contractFlyoutRef"
        v-bind="contractProps"
        :vendor="contractProps && contractProps.vendor && contractProps.vendor.VendorRef ? contractProps.vendor.VendorRef : null"
        :agency="contractProps && contractProps.agency && contractProps.agency.AgencyRef ? contractProps.agency.AgencyRef : null"
    ></contract-flyout>

    <!-- Page: pages/browse-budgets -->

    <br />

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr>

    <div class="container dfw">
        <h1>Browse Budgets</h1>
        <p class="subhead">Current Fiscal Year Agency Budgets</p>
        <user-tools/>
    </div>

    <hr>

    <div class="container">
        <div class="browse-contacts-filter-wrapper">
            <p class="card__info__label step-label mb-5">
                <label>Select an Agency</label>
                <a class="state-budget" href="/browse-budgets" v-if="agencyId">or view entire State Budget</a>
            </p>
            <div class="mb-30">
                <custom-select
                    :uniqueId="uniqueId"
                    :items="[{value: '', name: 'All Agencies'}].concat(agencies.map(item => ({value: item.contentId, name: item.name})).filter(x => x.name !== 'Legislative Branch' && x.name !== 'Administered Funds'))"
                    name="agency"
                    placeholder="Select an Agency..."
                    v-model:value="agencyId"
                ></custom-select>
            </div>
            <!--
            <div class="mb-30">
                <custom-select
                    :uniqueId="uniqueId"
                    :items="domains"
                    name="domain"
                    placeholder="Select a Domain..."
                    v-model:value="domain"
                ></custom-select>
            </div>
            -->
        </div>

        <general-container
            :customClass="'column'"
            :isLocked="false"
        >
            <p id="state" class="stacked-bar-chart-title has-text-centered" v-if="!agencyId">State of Florida Budget</p>
            <p class="browse-budget-title has-text-centered">
                <div v-if="agencyId"><a :href="getAgencyUrl(agencyId)">{{ getAgencyName(agencyId) }}</a> Budget</div>
            </p>

            <div class="org-chart-container">

                <p class="general-container__title has-text-centered">Budget History</p>

                <div class="container view-type-container" v-if="historical && historical.length">
                    <div class="toggle">

                        <span class="toggle__title">Switch View:</span>

                        <span id="view-toggle--table--one"
                            v-on:click="toggleViewBudget()" type="checkbox"
                            :checked="budgetGraphView"
                            class="view-toggle"
                        >
                            <img src="@/assets/svg/table--inactive.svg"
                                alt="Show Graph View"
                                v-if="!budgetGraphView"
                                class="mr-10"
                            />
                            <img src="@/assets/svg/table--active.svg"
                                alt="Show Table View"
                                v-else
                                class="mr-10"
                            />
                        </span>

                        <span id="view-toggle--card--one"
                            v-on:click="toggleViewBudget()" type="checkbox"
                            :checked="budgetGraphView"
                            class="view-toggle"
                        >
                            <img src="@/assets/svg/card--active.svg"
                                alt="Show Graph View"
                                v-if="!budgetGraphView"
                            />
                            <img src="@/assets/svg/card--inactive.svg"
                                alt="Show Table View"
                                v-else
                            />
                        </span>
                    </div>
                </div>
                <div class="container container--historical-chart" v-if="budgetGraphView && historical && historical.length">
                    <state-budget-chart :historical="historical"/>
                </div>

                <div class="container container--historical" v-if="(!budgetGraphView && historical && historical.length)">
                    <div class="columns is-block">
                        <div class="column">

                        <!-- client requested removed for now: #yfahbx
                            <p class="general-container__title has-text-centered">{{
                                    (agencyId ? 'Agency' : '') + ' Budget History'
                                }}</p>
                            <div ref="programBudgetHistoryChartEl" class="programBudgetHistoryChartEl"></div>
                            <div ref="programBudgetHistoryLegendEl" class="programBudgetHistoryLegendEl"></div>
                        -->
                            <div class="tbl-scrollable responsive-table">
                                <table class="zebra">
                                    <thead>
                                    <tr>
                                        <th scope="col">Fiscal Year</th>
                                        <th scope="col">Spent</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="historicalBudget in historical">
                                        <td data-label="Fiscal Year">{{
                                                historicalBudget.fiscalYearFrom
                                            }} / {{ (historicalBudget.fiscalYearFrom + 1 + '').substr(2) }}
                                        </td>
                                        <td class="is-family-monospace has-text-right" data-label="Spent">
                                            ${{ historicalBudget.disbursementAmount < historicalBudget.appropriationAmount ? priceFormat(historicalBudget.disbursementAmount) : priceFormat(historicalBudget.appropriationAmount) }}
                                            ({{
                                                historicalBudget.disbursementAmount ?
                                                    historicalBudget.disbursementAmount < historicalBudget.appropriationAmount ?
                                                    (Math.round(
                                                        historicalBudget.disbursementAmount
                                                        / historicalBudget.appropriationAmount * 100
                                                    )) :
                                                    100
                                                    :
                                                0
                                            }}%)
                                        </td>
                                        <td class="is-family-monospace has-text-right" data-label="Total">
                                            ${{ priceFormat(historicalBudget.appropriationAmount) }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br/>
            <br/>

            <div class="org-chart-container" v-if="!agencyId && agencies.length > 0">
                <container-search :search-data="agencies"
                                  :item-selected="scrollToTile"
                                  :name="'State Agency Budgets'"
                                  :message="'NOTE: This search returns budgets within this State of Florida agency budget map.'"
                >
                </container-search>
                <p class="general-container__title has-text-centered">State Agency Budgets</p>

                <div class="container view-type-container" v-if="!agencyId && agencies.length > 0">
                    <div class="toggle">

                        <span class="toggle__title">Switch View:</span>

                        <span id="view-toggle--table--two"
                            v-on:click="toggleViewTreemap()" type="checkbox"
                            :checked="graphView"
                            class="view-toggle"
                        >
                            <img src="@/assets/svg/table--inactive.svg"
                                alt="Show Graph View"
                                v-if="!graphView"
                                class="mr-10"
                            />
                            <img src="@/assets/svg/table--active.svg"
                                alt="Show Table View"
                                v-else
                                class="mr-10"
                            />
                        </span>

                        <span id="view-toggle--card--two"
                            v-on:click="toggleViewTreemap()" type="checkbox"
                            :checked="graphView"
                            class="view-toggle"
                        >
                            <img src="@/assets/svg/card--active.svg"
                                alt="Show Graph View"
                                v-if="!graphView"
                            />
                            <img src="@/assets/svg/card--inactive.svg"
                                alt="Show Table View"
                                v-else
                            />
                        </span>

                    </div>
                </div>
                <div class="container" v-if="isTreemapDetailVisible && graphView && !agencyId && agencies.length > 0">
                    <preloader v-if="!tree || Object.keys(tree).length === 0"/>
                    <state-agency-budgets-treemap ref="StateAgencyBudgetsTreemapRef" v-else :tree="tree"/>
                </div>
            </div>

            <div class="responsive-table" v-if="!graphView">
                <table class="zebra" v-if="!agencyId && agencies.length > 0">
                    <thead>
                        <tr class="tbl-sortable">
                            <th @click="sortField = 'name'; sortIsAsc = !sortIsAsc" scope="col">
                                Agency {{ sortField === 'name' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                            </th>
                            <th @click="sortField = 'budgetAppropriations'; sortIsAsc = !sortIsAsc" scope="col">
                                Budget {{ sortField === 'budgetAppropriations' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                            </th>
                            <th @click="sortField = 'budgetDisbursements'; sortIsAsc = !sortIsAsc" scope="col">
                                Spent {{ sortField === 'budgetDisbursements' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                            </th>
                            <th scope="col">
                                Remaining
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="agency in nonZeroRows">
                            <td data-label="Agency">
                                <div v-if="agency.name === 'Administered Funds' || agency.name === 'Legislative Branch'">
                                    {{ titleize(agency.name)}}
                                </div>
                                <div v-else>
                                    <a v-on:click="changeAgencyId(agency.contentId)" :href="'/browse-budgets?agencyId=' + agency.contentId">{{
                                        titleize(agency.name)
                                    }}</a>
                                </div>
                            </td>
                            <td class="is-family-monospace has-text-right" data-label="Budget">${{ priceFormat(agency.budgetAppropriations) }}</td>
                            <td class="is-family-monospace has-text-right" data-label="Spent">${{ priceFormat(agency.budgetDisbursements) }} ({{
                                    getPercent(agency, 'budgetDisbursements')
                                }}%)
                            </td>
                            <td class="is-family-monospace has-text-right" data-label="Remiaining">${{ priceFormat(agency.budgetAppropriations - agency.budgetDisbursements) }}
                                ({{ getPercent(agency, 'remaining') }}%)
                            </td>
                        </tr>
                        <tr class="total">
                            <td class="total-1">Total</td>
                            <td class="total-2 is-family-monospace has-text-right">${{ priceFormat(getTotal('budgetAppropriations')) }}</td>
                            <td class="total-3 is-family-monospace has-text-right">${{ priceFormat(getTotal('budgetDisbursements')) }}</td>
                            <td class="total-3 is-family-monospace has-text-right">${{ priceFormat(getTotal('remaining')) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <preloader v-if="loading || agencies.length == 0"></preloader>

            <!--
            <top-vendors-block
                        titleClass="has-text-centered" customContainerClass="browse-budget-container"
                        title="Top Vendors"
            />
            -->

            <agency-budget-block
                v-if="!loading && agencyId"
                :agency-id="agencyId"
                :progressStyle="{height: '60px'}"
                :hideFullBudgetLink="true"
                title-class="has-text-centered"
                container-class="browse-budget-container"
            ></agency-budget-block>

          <container-search v-if="agencyId" 
                            :search-data="budgets"
                            :item-selected="scrollToTile"
                            :name="'Agency Budgets Details'"
                            :message="'NOTE: This search returns budgets details map.'"
          >
          </container-search>

            <br>
            <p class="general-container__title has-text-centered" v-if="budgets.length > 0">Budget Details</p>

            <div class="container view-type-container" v-if="budgets && budgets.length > 0">
                <div class="toggle">

                    <span class="toggle__title">Switch View:</span>

                    <span id="view-toggle--table--three"
                        v-on:click="toggleViewBudgetDetails()" type="checkbox"
                        :checked="budgetDetailsGraphView"
                        class="view-toggle"
                    >
                        <img src="@/assets/svg/table--inactive.svg"
                            alt="Show Graph View"
                            v-if="!budgetDetailsGraphView"
                            class="mr-10"
                        />
                        <img src="@/assets/svg/table--active.svg"
                            alt="Show Table View"
                            v-else
                            class="mr-10"
                        />
                    </span>

                    <span id="view-toggle--card--three"
                        v-on:click="toggleViewBudgetDetails()" type="checkbox"
                        :checked="budgetDetailsGraphView"
                        class="view-toggle"
                    >
                        <img src="@/assets/svg/card--active.svg"
                            alt="Show Graph View"
                            v-if="!budgetDetailsGraphView"
                        />
                        <img src="@/assets/svg/card--inactive.svg"
                            alt="Show Table View"
                            v-else
                        />
                    </span>

                </div>
            </div>

            <div v-if="budgets.length > 0 && budgetDetailsGraphView" class="budget-details-container">
                <div class="treemap-wrapper">
                    <div ref="budgetDetailsChartEl" class="treemap-container"></div>
                </div>
            </div>

            <div class="responsive-table with-bottom-padding" v-if="!budgetDetailsGraphView">
                <table class="zebra" v-if="budgets.length > 0">
                    <thead>
                        <tr class="tbl-sortable">
                            <th @click="programSortField = 'category'; programSortIsAsc = !programSortIsAsc" scope="col">
                                Program {{ programSortField === 'category' ? (programSortIsAsc ? '⇩' : '⇧') : '' }}
                            </th>
                            <th @click="programSortField = 'name'; programSortIsAsc = !programSortIsAsc" scope="col">
                                Budget Entity {{ programSortField === 'name' ? (programSortIsAsc ? '⇩' : '⇧') : '' }}
                            </th>
                            <th @click="programSortField = 'disbursementAmount'; programSortIsAsc = !programSortIsAsc" scope="col">
                                Amount Spent {{ programSortField === 'disbursementAmount' ? (programSortIsAsc ? '⇩' : '⇧') : '' }}
                            </th>
                            <th @click="programSortField = 'appropriationAmount'; programSortIsAsc = !programSortIsAsc" scope="col">
                                Total Budget {{ programSortField === 'appropriationAmount' ? (programSortIsAsc ? '⇩' : '⇧') : '' }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="budget in budgets">
                            <td data-label="Program"><a :href="budget.program.url" v-if="budget.category">{{ titleize(budget.program.name) }}</a></td>
                            <td data-label="Budget Entity"><a :href="budget.url">{{ titleize(budget.name) }}</a></td>
                            <td class="is-family-monospace has-text-right" data-label="Amount Spent">${{ priceFormat(budget.disbursementAmount) }}</td>
                            <td class="is-family-monospace has-text-right" data-label="Total Budget">${{ priceFormat(budget.appropriationAmount) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="container contracts-container" v-if="!loading && agencyId || domain">
                <div class="columns">
                    <div class="column">
                        <contracts-table
                            :uniqueId="uniqueId"
                            :key="agencyId"
                            :agency-id-only="agencyId"
                            :search-agency="false"
                            :search-type="true"
                            :search-fiscal="true"
                            :search-domain="false"
                            :vendorshow="true"
                            titleClass="has-text-centered"
                            customContainerClass="browse-budget-container"
                            title="Agency Contracts"
                            :hideAgencyName="true"
                            :subflyout="true"
                        />
                    </div>
                </div>
            </div>
        </general-container>

    </div>
    <!-- End Page: pages/browse-budgets -->
</template>

<script>
    import AgencyBudgetBlock from "./agency-budget-block";
    import ContractsTable from "./contracts-table";
    import ContainerSearch from "./container-search";
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "./breadcrumbs";
    import GeneralContainer from "./general-container";
    import UserTools from "@/alivue/components/user-tools";
    import Pagination from "./pagination";
    import Preloader from "./preloader";
    import { useStore } from "vuex";
    import { computed, onMounted, ref, watch } from "vue";
    import AgencyBudgetGroup from "./agency-budget-group";
    import { useRouter } from "vue-router";
    import { loadAgencyBudgets } from "@/helpers/budgets";
    import CustomSelect from "./custom-select";
    import { priceFormat } from "@/helpers/filter";
    import RequestHandler from "@/handler/RequestHandler";
    import { renderBudgetDetailsTreemap } from "@/helpers/charts";
    import StateBudgetChart from "./state-budget-chart";
    import StateAgencyBudgetsTreemap from "./state-agency-budgets-treemap";
    import TopVendorsBlock from "./top-vendors-block";
    import { useEventsBus } from "@/helpers/eventBus";
    import VendorFlyout from "./vendor-flyout";
    import { scrollToTile, clickToTile } from "@/helpers/budgetTreeMap";
    import ContractFlyout from "./contract-flyout";

    export default {
        components: {
            CustomSelect,
            AgencyBudgetGroup,
            AgencyBudgetBlock,
            ContainerSearch,
            ContractsTable,
            Preloader,
            Alert,
            Breadcrumbs,
            GeneralContainer,
            UserTools,
            Pagination,
            StateBudgetChart,
            StateAgencyBudgetsTreemap,
            TopVendorsBlock,
            VendorFlyout,
            ContractFlyout,
        },
        props: ['uniqueId'],
        methods: {
            toggleViewTreemap() {
                this.graphView = !this.graphView;
            },
            toggleViewBudget() {
                this.budgetGraphView = !this.budgetGraphView;
            },
            toggleViewBudgetDetails() {
                this.budgetDetailsGraphView = !this.budgetDetailsGraphView;
                if (this.budgets.length > 0 && this.budgetDetailsGraphView) {
                    this.$nextTick(() => {
                        renderBudgetDetailsTreemap(
                            this.$refs.budgetDetailsChartEl,
                            this.budgets,
                            this.treemapCallback
                        ).then((resolvedValue) => {
                            // Use the ref value directly to set treemap
                            this.treeMap.value = resolvedValue.controller;
                        });
                    });
                }
            },
            getTotal: function(field) {
                let x = 0;
                for (let i = 0; i < this.agencies.length; i++) {
                    if (field === 'remaining') {
                        x += (this.agencies[i].budgetAppropriations - this.agencies[i].budgetDisbursements);
                    } else {
                        x += this.agencies[i][field];
                    }
                }
                return x;
            },
            changeAgencyId(id) {
                this.agencyId = id;
            },
            getPercent: function(agency, field) {
                let percent = 0;
                if (field === 'remaining') {
                    percent = (agency.budgetAppropriations - agency.budgetDisbursements) / agency.budgetAppropriations;
                } else {
                    percent = agency[field] / agency.budgetAppropriations;
                }
                let answer = (100 * percent).toFixed(1);
                if (isNaN(answer)) {
                    return 0;
                }
                return answer;
            },
            getAgencyName: function(id) {
                return this.agencies.find( function (item) {
                    return item.contentId == id;
                } )?.name || '';
            },
            getAgencyUrl: function(id) {
                return this.agencies.find( function (item) {
                    return item.contentId == id;
                } )?.url || '';
            },
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            }
        },
        computed: {
            // prevents rows with zero budget from rendering in the table. based on the assumption that a zero budget will always have zero spent and always have zero remaining (#30m768q)
            nonZeroRows: function() {
                 return this.agenciesSorted.filter(i => i.budgetAppropriations != '0');
            }
        },
        setup(props) {

            const isTreemapDetailVisible = ref(true);
            const { bus } = useEventsBus();
            const vendorFlyoutRef = ref(null);
            const contractFlyoutRef = ref(null);
            const StateAgencyBudgetsTreemapRef = ref(null);

            watch(isTreemapDetailVisible, (newValue) => {
                if (!newValue) {
                    // The value is now false, you can perform additional logic here if needed
                    console.log("hideMe element should be hidden now");
                }
            });

            function treemapCallback(callbackResult) {
                isTreemapDetailVisible.value = callbackResult;
            }

            watch( () => bus.value.get('call-open-flyout-contract'),
                async (val) => {
                    contractProps.value = null;
                    if (contractFlyoutRef.value) {
                        console.log(contractFlyoutRef.value);
                        try {
                            const contractRef = await contractFlyoutRef.value.openFlyout(val[0]);
                            contractProps.value = contractRef;
                            var x = document.getElementById("snackbar-vendor-contract");
                            x.classList.add('show');
                            x.classList.add('fadein');
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            watch( () => bus.value.get('call-open-flyout-vendor'),
                async (val) => {
                    vendorProps.value = null;
                    if (vendorFlyoutRef.value) {
                        try {
                            const vendorRef = await vendorFlyoutRef.value.openFlyoutVendor(val[0]);
                            vendorProps.value = vendorRef;
                            var x = document.getElementById("snackbar-vendor");
                            x.classList.add('show');
                            x.classList.add('fadein');
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            watch( () => bus.value.get('call-link-to-treemap'),
                async (val) => {
                    if (StateAgencyBudgetsTreemapRef.value) {
                        try {
                            clickToTile(val[0][0], StateAgencyBudgetsTreemapRef.value.getTreemap(), val[0][1]);
                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        clickToTile(val[0][0], treeMap.value, val[0][1]);
                    }
                }
            );

            const vendorProps = ref(null);
            const store = useStore();
            const router = useRouter();
            const agencies = computed(() => store.getters.agencies || []);
            const loading = ref(false);
            const budgets = ref([]);
            const agencyId = ref('');
            const domain = ref('');
            const domainValues = ref([]);
            const domains = ref([
                {
                    value: '',
                    name: 'All Domains',
                },
                {
                    value: 'Health and Human Services',
                    name: 'Health and Human Services',
                },
                {
                    value: 'Justice and Public Safety',
                    name: 'Justice and Public Safety',
                },
                {
                    value: 'Environment, Agriculture, and Natural Resources',
                    name: 'Environment, Agriculture, and Natural Resources',
                },
                {
                    value: 'Infrastructure, Tourism, and Economic Development',
                    name: 'Infrastructure, Tourism, and Economic Development',
                },
                {
                    value: 'Education',
                    name: 'Education',
                },
                {
                    value: 'General Government, State Administration, and Technology',
                    name: 'General Government, State Administration, and Technology',
                },
                {
                    value: 'Legislature - House and Senate',
                    name: 'Legislature - House and Senate',
                },
            ]);
            const historical = ref([]);
            const programBudgetHistoryChartEl = ref(null);
            const programBudgetHistoryLegendEl = ref(null);
            const budgetDetailsChartEl = ref(null);

            const contractProps = ref(null);

            const sortField = ref('name');
            const sortIsAsc = ref(true);
            const programSortField = ref('appropriationAmount');
            const programSortIsAsc = ref(false);
            const agenciesSorted = ref([]);

            const graphView = ref(true);
            const tree = ref({});

            const treeMap = ref(null);

            const budgetGraphView = ref(true);
            const budgetDetailsGraphView = ref(true);

            const loadHistoricalBudgets = () => {
                historical.value = [];
                RequestHandler.loadHistoricalBudgets(agencyId.value, domain.value).then(response => {
                    historical.value = response;

                    // comment out for now, more yfahbx
                    // give vue some time to resolve ref
                    // setTimeout(() => {
                    //    programBudgetHistoryChartEl.value.innerHTML = '';
                    //    programBudgetHistoryLegendEl.value.innerHTML = '';
                    //    renderProgramBudgetHistoryChart(
                    //        programBudgetHistoryChartEl.value, programBudgetHistoryLegendEl.value, response
                    //    )
                    //}, 100);
                });
            }
            const updateSorted = () => {
                if (!agencies.value.length || agencyId.value) {
                    agenciesSorted.value = agencies.value;
                    return;
                }

                agenciesSorted.value = agencies.value.concat().sort((itemA, itemB) => {
                    if (itemA[sortField.value] < itemB[sortField.value]) {
                        return sortIsAsc.value ? -1 : 1;
                    }
                    if (itemA[sortField.value] > itemB[sortField.value]) {
                        return sortIsAsc.value ? 1 : -1;
                    }
                    return 0;
                });
            }
            const updateSortedBudget = () => {
                if (!budgets.value.length) {
                    return;
                }
                budgets.value = budgets.value.concat().sort((itemA, itemB) => {
                    if (itemA[programSortField.value] < itemB[programSortField.value]) {
                        return programSortIsAsc.value ? -1 : 1;
                    }
                    if (itemA[programSortField.value] > itemB[programSortField.value]) {
                        return programSortIsAsc.value ? 1 : -1;
                    }
                    return 0;
                });
            }
            const getTree = () => {
                RequestHandler.loadAgenciesTree().then((response) => {
                    tree.value = response;
                });
            }

            watch(agencies, updateSorted);
            watch(sortField, updateSorted);
            watch(sortIsAsc, updateSorted);
            watch(programSortField, updateSortedBudget);
            watch(programSortIsAsc, updateSortedBudget);
            updateSortedBudget();

            watch(agencyId, (now) => {
                if (now) {
                    loadAgencyBudgets(now, budgets, loading, false).then(() => {
                        budgets.value.forEach((item) => {
                            item.category = (item.program?.url && item.program.name) || '';
                            if (!item.category) {
                                item.program.url = item.url;
                                item.category = item.program.name;
                            }
                        });
                        if (typeof budgetDetailsChartEl != 'undefined') {
                            renderBudgetDetailsTreemap(
                                budgetDetailsChartEl.value,
                                budgets.value,
                                treemapCallback
                            ).then((resolvedValue) => {
                                // Use the ref value directly to set treemap
                                treeMap.value = resolvedValue.controller;
                            });
                        }

                    });
                    router.push({ name: 'browse-budgets', query: { 'agencyId': agencyId.value, 'domain': domain.value } });
                } else {
                    budgets.value = [];
                }
                loadHistoricalBudgets();
            });

            watch(domain, (now) => {
                if (now) {
                    loadAgencyBudgets(now, budgets, loading, false).then(() => {
                        budgets.value.forEach((item) => {
                            item.category = (item.program?.url && item.program.name) || '';
                            if (!item.category) {
                                item.program.url = item.url;
                                item.category = item.program.name;
                            }
                        });
                        renderBudgetDetailsTreemap(
                            budgetDetailsChartEl.value,
                            budgets.value,
                            treemapCallback
                        ).then((resolvedValue) => {
                            // Use the ref value directly to set treemap
                            treeMap.value = resolvedValue.controller;
                        });
                    });
                    router.push({ name: 'browse-budgets', query: { 'agencyId': agencyId.value, 'domain': domain.value } });
                } else {
                    budgets.value = [];
                }
                loadHistoricalBudgets();
            });
            watch(router.currentRoute, () => {
                agencyId.value = router.currentRoute.value.query.agencyId || '';
            });

            onMounted(() => {
                if (router.currentRoute.value.query.agencyId) {
                    agencyId.value = router.currentRoute.value.query.agencyId;
                }
                updateSorted();
                loadHistoricalBudgets();
                getTree();
            });

            const showSearch = computed(() => store.getters.showSearch);

            return {
                isTreemapDetailVisible,
                loading,
                budgets,
                agencies,
                agenciesSorted,
                agencyId,
                domains,
                domain,
                path: [{ title: 'Browse Budgets' }],
                showSearch,
                priceFormat,
                scrollToTile,
                sortField,
                sortIsAsc,
                programSortField,
                programSortIsAsc,
                historical,
                programBudgetHistoryChartEl,
                programBudgetHistoryLegendEl,
                graphView,
                tree,
                budgetGraphView,
                budgetDetailsGraphView,
                budgetDetailsChartEl,
                vendorProps,
                vendorFlyoutRef,
                contractFlyoutRef,
                StateAgencyBudgetsTreemapRef,
                treeMap,
                contractProps,
            };
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/helpers/responsive-tables";


    .with-bottom-padding {
        padding-bottom: 20px;
    }

    // @todo: move styles to some scss if possible, to not duplicate with agency-contacts
    .browse-contacts-filter-wrapper {
        max-width: 50%;
        margin: auto;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }

        .card__info__label {
            label {
                font-size: 28px;
                font-weight: 600;
                font-family: $header;
                text-transform: capitalize;
                margin-bottom: 5px;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    display: block;
                }
            }
        }
    }

    .custom-select {
        width: 100%;
    }

    .step-label {
        font-size: 1.75em;
    }

    .select {
        width: 98%;

        .custom-select {
            border-width: 2px;
            width: 100%;
        }
    }

    .total {
        font-weight: bold;
    }

    .total-1 {
        text-align: right;
    }

    table {
        margin: auto;
        display: inline-table;

        th {
            color: $white;
            background: $blue--dark;
            background: $blue-gradient;
        }

        td, th {
            padding: 10px;
            border: solid 1px $grey--dark;
        }
        td a {
            &:hover, &:focus, &:active {
                color: $blue--dark;
            }
        }
        &.zebra {
            width: 100%;
        }
    }

    .zebra tr:nth-child(even) {
        //background: #eee;
    }

    .zebra tr:nth-child(odd) {
        background-color: $white;
    }

    .tbl-sortable th {
        cursor: pointer;
    }

    .container--historical {
        margin-bottom: 50px;
        margin-top: 20px;
    }

    .browse-budget-title {
        font-weight: 600;
        font-family: $header;
        font-size: 40px;
        margin-bottom: 0.5em;
    }
    .browse-budget-container {
        box-shadow: none;
    }
    .tbl-scrollable {
        table {
            width: 100%;
            background-color: $white;
        }
    }
    .is-block {
        display: block !important;
    }

    .toggle {
        margin-left: 15px;
    }
    .view-type-container {
        margin-bottom: 30px;
        text-align: right;
    }
    .view-toggle img {
        max-width: 24px;
    }
    .toggle__title {
        margin-right: 10px;
    }
    .subhead {
        font-weight: 400;
        margin-bottom: 0!important;
        font-size: 14px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-family: $body;
        color: $primary;
        position: absolute;
        top: 65px;
        @media screen and (max-width: 1021px) {
            text-align: center;
            width: 100%;
            position: initial;
        }
    }
    .org-chart-container {
        padding: 15px;
    }
    .state-budget {
        text-transform: none;
        font-size: 14px;
        letter-spacing: inherit;
        margin-left: 5px;
        @media screen and (max-width: 768px) {
            top: -15px;
            position: relative;
            margin-left: 0px;
        }
    }

    .budget-details-container {
        margin-bottom: 100px;
    }

    .treemap-container {
        //max-width: 600px;
        position: relative;
    }

    .treemap-tooltip {
        position: absolute;
        background-color: $white;
        border: 2px solid $blue--dark;
        border-radius: 4px;
        padding: 5px;
        transform: translateX(-50%);
        text-align: center;
        width: 200px;

        .treemap-tooltip-type {
            color: $blue--dark;
            font-size: 16px;
        }
        .treemap-tooltip-title {
            color: $grey--dark;
            font-size: 14px;
            font-weight: normal;
        }

        &:hover, &:active, &:focus {
            display: block !important;
        }

        &:after {
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            left: 50%;
            top: -6px;
            width: 0;
            height: 0;
            border-color: transparent transparent $blue--dark;
            border-style: solid;
            border-width: 0 5px 5px;
            content: "";
        }
    }
    tspan[underline*="true"] {
        text-decoration: none;
        tspan#underline {
            text-decoration: underline;
        }
    }
    #tbl-keyword {
       height: 55px;
    }
</style>
