<template>

    <general-container v-if="totalVendorsCount" :title="title" :titleClass="titleClass" :custom-class="customContainerClass">
         <div class="responsive-table" :class="scrollable == false ? '' : 'tbl-scrollable'" >
            <table class="zebra" :key="random">
                <thead>
                    <tr>
                        <th class="not-sortable" scope="col">
                            Vendor Name
                        </th>
                        <th class="clickable" @click="sortField = 'aggContractTotal:'; sortIsAsc = !sortIsAsc" scope="col">
                            Aggregated contract total {{ sortField === 'aggContractTotal:' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                        </th>
                        <th class="clickable" @click="sortField = 'numContracts'; sortIsAsc = !sortIsAsc" scope="col">
                            Total contract count {{ sortField === 'numContracts' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="vendors, vendorIndex) in totalVendorsCount" :key="'totalVendorsCount' + i">
                        <tr>
                            <td data-label="Name">
                                {{vendors.name}}
                            </td>
                            <td data-label="aggContractTotal">
                                ${{ priceFormat(vendors.aggContractTotal) }}
                            </td>
                            <td data-label="numContracts">
                                {{ vendors.numContracts }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </general-container>

</template>


<script>
import RequestHandler from "@/handler/RequestHandler";
    import GeneralContainer from "./general-container";
    import { computed, onMounted, ref, watch } from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import { priceFormat } from "@/helpers/filter";
  

    export default {
        name: 'TopVendorsBlock',
        components: {
            Preloader,
            GeneralContainer,
        },
        props: [ 'title', 'titleClass',  'customContainerClass' ],
        setup(props) {
           
            const vendors = ref([]);
            const totalVendorsCount = ref(null);
            const loading = ref(true);
        
            const loadTopVendors = () => {
                totalVendorsCount.value = null;
                loading.value = true;
                RequestHandler.loadTopVendors()
                    .then(data => {
                        loading.value = false;
                        totalVendorsCount.value = vendors.value.concat(...(data || []));
                        })
                    .catch(error => {
                        loading.value = false;
                        console.error(error)
                    });
            };
            onMounted(() => {
                loadTopVendors();
            });

            return {
                loading,
                totalVendorsCount,
                priceFormat,

            }
        }
    }
</script>