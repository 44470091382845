<template>
    <!-- Start Component: organisms/state-agency-budgets-treemap -->
    <div v-if="isTreemapDetailVisible" class="treemap-wrapper">
        <preloader v-if="!tree || Object.keys(tree).length === 0"></preloader>
        <div ref="chartEl" class="treemap-container"></div>
    </div>
    <!-- End Component: organisms/state-agency-budgets-treemap -->
</template>

<script>
    import { onMounted, ref, watch } from "vue";
    import { renderStateAgencyBudgetsTreemap } from "@/helpers/charts";
    import { CHART_COLORS } from "@/constants";
    import { useRouter, useRoute } from 'vue-router';
    import RequestHandler from "@/handler/RequestHandler";
    import Preloader from "./preloader";

    export default {
        name: 'StateAgencyBudgetsTreemap',
        data() {
            return {
                treeMap: null,
            };
        },
        methods: {
            getTreemap() {
                return this.treeMap;
            },
        },
        props: ['tree',],
        components: {
            Preloader,
        },
        setup(props) {
            function treemapCallback(callbackResult) {
                isTreemapDetailVisible.value = callbackResult;
            }
            const isTreemapDetailVisible = ref(true);
            const chartEl = ref(null);
            const tree = ref(props.tree || {});
            const treeMap = ref(null);

            watch(isTreemapDetailVisible, (newValue) => {
                if (!newValue) {
                    // The value is now false, you can perform additional logic here if needed
                    console.log("hideMe element should be hidden now");
                }
            });

            onMounted(() => {
                renderStateAgencyBudgetsTreemap(
                    chartEl.value,
                    tree.value,
                    treemapCallback
                ).then((resolvedValue) => {
                    // Use the ref value directly to set treemap
                    treeMap.value = resolvedValue.controller;
                });
            });

            return {chartEl, CHART_COLORS, tree, isTreemapDetailVisible, treeMap};
        },
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";

    .treemap-container {
        //max-width: 600px;
    }

    .hide-text + clipPath + text {
        display: none;
    }

    .chart-legend {
        text-align: left;
        span {
            font-size: 2.4em;
            padding-right: 10px;
            line-height: 10px;
        }
    }

    .treemap-tooltip {
        position: absolute;
        background-color: $white;
        border: 2px solid $blue--dark;
        border-radius: 4px;
        padding: 5px;
        transform: translateX(-50%);
        text-align: center;
        width: 200px;

        .treemap-tooltip-close {
            background-color: $white;
            border: none;
            cursor: pointer;
            float: right;

            &:hover, &:focus {
                text-decoration: underline !important;
            }
        }

        &:hover, &:active, &:focus {
            display: block !important;
            text-decoration: underline;
        }

        &:after {
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            left: 50%;
            top: -6px;
            width: 0;
            height: 0;
            border-color: transparent transparent $blue--dark;
            border-style: solid;
            border-width: 0 5px 5px;
            content: "";
        }
    }
    tspan[underline*="true"] {
        text-decoration: none;
        tspan#underline {
            text-decoration: underline;
        }
    }
</style>
